import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { environment } from "../../environments/environment";

import { Client } from '../models/client';

@Injectable()
export class ClientContext {
  
  private _clientServerUrl: string;

  public activeClient: Client;
  public isClientSet: ReplaySubject<boolean>;
  public isClientLoaded: ReplaySubject<boolean>;

  get clientServerUrl(): string {
    return this._clientServerUrl;
  }

  constructor() {
    this.isClientSet = new ReplaySubject(1);
    this.isClientLoaded = new ReplaySubject(1);
  }

  setClient(clientMap: Client): void {
    this.activeClient = clientMap;
    this._clientServerUrl = `${environment.clientServerProtocol}://${clientMap.clientSubDomain}.${environment.clientServerDomain}`;
    this.isClientSet.next(true);
  }

  markClientAsLoaded(): void {
    this.isClientLoaded.next(true);
  }

  clearClient(): void {
    this.activeClient = null;
    this._clientServerUrl = null;
    this.isClientSet.next(false);
    this.isClientLoaded.next(false);
  }

}
