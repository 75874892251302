import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'

import { ClientContext } from './client.context'
import { AccountContext } from './account.context'
import { HubService } from './hub.service'

import { AuthConstants } from '../reference/auth-consts'

import { User } from '../models/user'
import { Client } from '../models/client'

@Injectable()
export class AccountService {
  readonly tokenEndpoint = '/api/token/auth'
  readonly userEndpoint = '/api/users/'
  readonly settingsEndpoint = '/api/settings'

  constructor(
    private http: HttpClient,
    private clientContext: ClientContext,
    private accountContext: AccountContext,
    private hubService: HubService,
  ) {}

  login(username: string, password: string): Observable<User> {
    return Observable.create((observer) => {
      let handleError = (err) => {
        observer.error(err)
        this.hubService.forgetClient()
      }

      this.hubService
        .getClientForLogin(username)
        .subscribe((clientMap: Client) => {
          this.hubService.setClient(clientMap)

          const loginRequest = {
            username: username,
            password: password,
            clientId: clientMap.clientSubDomain,
            grantType: 'password',
          }

          this.http
            .post(
              this.clientContext.clientServerUrl + this.tokenEndpoint,
              loginRequest,
            )
            .subscribe((data: any) => {
              localStorage.setItem(
                AuthConstants.authTokenKey,
                data.access_token,
              )
              localStorage.setItem(AuthConstants.authExpiryKey, data.expires_in)
              localStorage.setItem(AuthConstants.authUserId, data.user_id)

              this.completeLogin(data.user_id).subscribe((user: User) => {
                observer.next(user)
                observer.complete()
              }, handleError)
            }, handleError)
        }, handleError)
    })
  }

  updateAccount(fullName: string, email: string): Observable<User> {
    return Observable.create((observer) => {
      let updatedUser = {} as User
      Object.assign(updatedUser, this.accountContext.loggedInUser)

      updatedUser.fullName = fullName
      updatedUser.email = email

      this.http
        .put(
          this.clientContext.clientServerUrl +
            this.userEndpoint +
            updatedUser.userId +
            '/current',
          updatedUser,
        )
        .subscribe(
          (user: User) => {
            this.accountContext.login(user)

            observer.next(user)
            observer.complete()
          },
          (err) => {
            observer.error(err)
          },
        )
    })
  }

  updateUserPassword(
    currentPassword: string,
    newPassword: string,
    userId: string,
  ) {
    return Observable.create((observer) => {
      let request = {
        currentPassword: currentPassword,
        newPassword: newPassword,
      }

      this.http
        .put(
          this.clientContext.clientServerUrl +
            this.userEndpoint +
            userId +
            '/password',
          request,
        )
        .subscribe(
          (user: User) => {
            this.accountContext.login(user)
            observer.next(user)
            observer.complete()
          },
          (err) => {
            observer.error(err)
          },
        )
    })
  }

  updatePassword(
    currentPassword: string,
    newPassword: string,
  ): Observable<User> {
    let userId = this.accountContext.loggedInUser.userId
    return this.updateUserPassword(
      currentPassword,
      newPassword,
      userId.toString(),
    )
  }

  resetPassword(emailAddress: string): Observable<User> {
    return Observable.create((observer) => {
      let request = {
        emailAddress: emailAddress,
      }

      this.http
        .put(
          this.clientContext.clientServerUrl +
            this.userEndpoint +
            'passwordreset',
          request,
        )
        .subscribe(
          () => {
            observer.next()
            observer.complete()
          },
          (err) => {
            observer.error(err)
          },
        )
    })
  }

  tryRestoreLogin(): Observable<User> {
    return Observable.create((observer) => {
      let authToken = localStorage.getItem(AuthConstants.authTokenKey)
      let userId = localStorage.getItem(AuthConstants.authUserId)
      if (!authToken || !userId) {
        return observer.error('Failed to restore user session - auth error')
      }

      let restoredClient = this.hubService.tryRestoreClient()
      if (!restoredClient) {
        return observer.error('Failed to restore user session - client error')
      }

      this.completeLogin(userId).subscribe(
        (user: User) => {
          observer.next(user)
          observer.complete()
        },
        (err) => {
          observer.error(err)
        },
      )
    })
  }

  completeLogin(userId): Observable<User> {
    return Observable.create((observer) => {
      this.http
        .get(this.clientContext.clientServerUrl + this.userEndpoint + userId)
        .subscribe(
          (user: User) => {
            this.hubService.loadClient().subscribe(() => {
              if (!user.passwordChangeRequired) {
                this.accountContext.login(user)
              }
              observer.next(user)
              observer.complete()
            })
          },
          (err) => {
            observer.error(err)
          },
        )
    })
  }

  logout(): void {
    sessionStorage.clear()
    this.accountContext.logout()
    localStorage.removeItem(AuthConstants.authTokenKey)
    localStorage.removeItem(AuthConstants.authExpiryKey)
    localStorage.removeItem(AuthConstants.authUserId)
    this.hubService.forgetClient()
  }

  getAuthQueryParam(): string {
    return (
      'authorization-bearer-token=' +
      localStorage.getItem(AuthConstants.authTokenKey)
    )
  }
}
