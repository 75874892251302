<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir" [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark, 'sidebar-opened': sidebarOpened, 'sidebar-closed': !sidebarOpened }"> 
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" class="topbar telative">
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon -->
                <b>
                  <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                  <!-- Dark Logo icon -->
                  <!-- <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo"> -->
                  <!-- Light Logo icon -->
                  <img src="assets/images/logo-icon-lge.png" alt="homepage" class="light-logo" style="max-width: 46px">
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span fxShow="false" fxShow.gt-xs>
                 <!-- dark Logo text -->
                 <!-- <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo"> -->
                 <!-- Light Logo text -->    
                 <!-- <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"> -->
                </span> 
                <span style="color: white">Intelligent Remote Invigilation System</span>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->
        
        <button mat-icon-button (click)="snav.toggle()" fxShow.sm="true" fxShow.xs="true"  fxShow.gt-lg="false"  fxShow="false">
            <mat-icon>menu</mat-icon>
        </button>
        <!-- ============================================================== -->
        <!-- Search - style you can find in header.scss -->
        <!-- ============================================================== -->
        <!-- <button mat-icon-button class="srh-btn">
            <mat-icon>search</mat-icon>
        </button>
        <form class="app-search">
            <input type="text" class="form-control" placeholder="Search &amp; enter"> 
            <a class="cl-srh-btn"><i class="ti-close"></i></a> 
        </form>  -->
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
        <!-- ============================================================== -->
        <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
        <!-- ============================================================== -->
        <!-- <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">
          <mat-icon>settings</mat-icon>
        </button> -->
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0" [ngClass]="{'minisidebar': minisidebar}">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true" (close)="sidebarOpened = false" >
            <app-sidebar></app-sidebar>
        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
        <!-- ============================================================== -->
        
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">
                 <perfect-scrollbar [config]="config">
                <div class="page-content">
                   
                        <router-outlet><app-route-spinner></app-route-spinner></router-outlet>
                    
                </div>    
               </perfect-scrollbar>    
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>