import { Injectable } from '@angular/core';
import { RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';

import { ClientContext } from './client.context';
import { ConfigService } from './config.service';

import { Config } from '../models/config';

@Injectable()
export class SettingsService {

  readonly settingsEndpoint = '/api/settings';

	constructor(
    private http: HttpClient, 
    private clientContext: ClientContext,
    private configService: ConfigService) { }
  
  getSettings() {
    return this.http.get(this.clientContext.clientServerUrl + this.settingsEndpoint);
  }

  updateSettings(settings: any) {
    return Observable.create(observer => {
      let onFailure = (err) => {
        observer.error(err);
      }

      this.http.post(this.clientContext.clientServerUrl + this.settingsEndpoint, settings)
        .subscribe((settings: any) => {
          
          // refresh app wide config
          this.configService.loadConfig()
            .then((value: Config) => {
              observer.next(settings);
              observer.complete();
            }).catch(onFailure);

        }, onFailure);
    });
  }

}