import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs/ReplaySubject'
import { Router } from '@angular/router'

import { ClientContext } from './client.context'
import { ReferenceDataService } from '../services/reference-data.service'

import { User } from '../models/user'

import { Roles } from '../reference/roles'

import * as _ from 'lodash'
import { Role } from '../models/role'

@Injectable()
export class AccountContext {
  public loggedInUser: User
  public isLoggedIn: ReplaySubject<boolean>

  private userSchoolRoleIds: number[]
  private adminRoleIds: Role[]
  private schoolAdminRoleIds: Role[]
  private lecturerRoleIds: Role[]
  private tutorRoleIds: Role[]
  private manageAssessmentsRoles: Role[]
  private manageUsersRoles: Role[]
  private manageFacultiesRoles: Role[]
  private deleteUsersRoles: Role[]
  private deleteAssessments: Role[]
  private manageSettingsRoles: Role[]

  private userHasRole(requiredRoleCollection: Role[]): boolean {
    return this.userSchoolRoleIds.some((r) =>
      _.map(requiredRoleCollection, 'roleId').includes(r),
    )
  }

  private updateRoleMappings() {
    this.adminRoleIds = _.filter(this.referenceDataService.getRoles, (x) => {
      return x.code === Roles.Admin
    })

    this.schoolAdminRoleIds = _.filter(
      this.referenceDataService.getRoles,
      (x) => {
        return x.code === Roles.SchoolAdmin
      },
    )

    this.lecturerRoleIds = _.filter(this.referenceDataService.getRoles, (x) => {
      return x.code === Roles.Lecturer
    })

    this.tutorRoleIds = _.filter(this.referenceDataService.getRoles, (x) => {
      return x.code === Roles.Tutor
    })

    this.manageAssessmentsRoles = _.filter(
      this.referenceDataService.getRoles,
      (x) => {
        return (
          x.code === Roles.Admin ||
          x.code === Roles.SchoolAdmin ||
          x.code === Roles.Lecturer
        )
      },
    )

    this.manageUsersRoles = _.filter(
      this.referenceDataService.getRoles,
      (x) => {
        return (
          x.code === Roles.Admin ||
          x.code === Roles.SchoolAdmin ||
          x.code === Roles.Lecturer
        )
      },
    )

    this.manageFacultiesRoles = _.filter(
      this.referenceDataService.getRoles,
      (x) => {
        return x.code === Roles.Admin || x.code === Roles.SchoolAdmin
      },
    )

    this.deleteUsersRoles = _.filter(
      this.referenceDataService.getRoles,
      (x) => {
        return x.code === Roles.Admin
      },
    )

    this.deleteAssessments = _.filter(
      this.referenceDataService.getRoles,
      (x) => {
        return x.code === Roles.Admin
      },
    )

    this.manageSettingsRoles = _.filter(
      this.referenceDataService.getRoles,
      (x) => {
        return x.code === Roles.Admin
      },
    )
  }

  private clearRoleMappings() {
    this.adminRoleIds = []
    this.schoolAdminRoleIds = []
    this.lecturerRoleIds = []
    this.tutorRoleIds = []
    this.manageAssessmentsRoles = []
    this.manageUsersRoles = []
    this.manageFacultiesRoles = []
    this.deleteUsersRoles = []
    this.deleteAssessments = []
    this.manageSettingsRoles = []
  }

  isAdministrator(): boolean {
    return this.userHasRole(this.adminRoleIds)
  }

  isSchoolAdmin(): boolean {
    return this.userHasRole(this.schoolAdminRoleIds)
  }

  isLecturer(): boolean {
    return this.userHasRole(this.lecturerRoleIds)
  }

  isTutor(): boolean {
    return this.userHasRole(this.tutorRoleIds)
  }

  canManageAssessments(): boolean {
    return this.userHasRole(this.manageAssessmentsRoles)
  }

  canManageUsers(): boolean {
    return this.userHasRole(this.manageUsersRoles)
  }

  canManageFaculties(): boolean {
    return this.userHasRole(this.manageFacultiesRoles)
  }

  canDeleteUsers(): boolean {
    return this.userHasRole(this.deleteUsersRoles)
  }

  canDeleteAssessments(): boolean {
    return this.userHasRole(this.deleteAssessments)
  }

  canManageSettings(): boolean {
    return this.userHasRole(this.manageSettingsRoles)
  }

  constructor(
    private clientContext: ClientContext,
    private referenceDataService: ReferenceDataService,
    private router: Router,
  ) {
    this.isLoggedIn = new ReplaySubject(1)

    this.clientContext.isClientLoaded.subscribe((isLoaded) => {
      if (isLoaded) {
        this.updateRoleMappings()
      } else {
        this.clearRoleMappings()
      }
    })
  }

  login(user: User): void {
    this.loggedInUser = user

    let roleIds = _.map(this.loggedInUser.schoolRoles, 'roleIds')
    this.userSchoolRoleIds = _.flatten(roleIds)

    this.isLoggedIn.next(true)
  }

  logout(): void {
    this.loggedInUser = null
    this.userSchoolRoleIds = []
    this.isLoggedIn.next(false)
  }

  redirectToHome(): void {
    if (!this.loggedInUser) {
      this.router.navigateByUrl('/authentication/login')
    } else {
      this.router.navigateByUrl('/units')
    }
  }
}
