import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material'
import { MatDialogRef } from '@angular/material'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
  confirmLabel = 'Delete'
  confirmColor = 'warn'

  constructor(
    public thisDialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.confirmLabel = data.confirmLabel
      ? data.confirmLabel
      : this.confirmLabel
    this.confirmColor = data.confirmColor
      ? data.confirmColor
      : this.confirmColor
  }

  ngOnInit() {}

  onCloseConfirm() {
    this.thisDialogRef.close('Confirm')
  }

  onCloseCancel() {
    this.thisDialogRef.close('Cancel')
  }
}
