import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ClientContext } from './client.context';

import { Config } from '../models/config';

@Injectable()
export class ConfigService {

  readonly dateFormatStorageKey = 'date_format';
  readonly configEndpoint = '/api/config/';

  private _config: Config = null;

  get config(): Config {
    return this._config;
  }

	constructor(
    private http: HttpClient,
    private clientContext: ClientContext) { }


  loadConfig(): Promise<Config> {
    return this.http.get(this.clientContext.clientServerUrl + this.configEndpoint)
      .toPromise()
      .then((config: Config) => {
        this._config = config;

        localStorage.setItem(this.dateFormatStorageKey, this._config.dateFormat);

        console.log('Client configuration loaded.', JSON.stringify(this._config));

        return this._config;
      }, err => {
        console.log('Failed to load client configuration.');

        return null;
      });
  }

  clearConfig() {
    this._config = null;
    localStorage.removeItem(this.dateFormatStorageKey);
    console.log('Client configuration cleared.');
  }
}