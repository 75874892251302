import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
/*
This service created with a purpose of sharing data
between sibling components
*/
@Injectable()
export class DataService {

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

}
