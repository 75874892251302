import { Injectable } from '@angular/core'
import { RequestOptions, URLSearchParams } from '@angular/http'
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { DatePipe } from '@angular/common'

import { environment } from '../../environments/environment'

import { ClientContext } from './client.context'
import { ConfigService } from './config.service'

import { Assessment } from '../models/assessment'
import {
  Session,
  ExaminationSessionDecision,
  SendNotification,
} from '../models/session'
import { SessionAuthStatus } from '../models/session-auth-status'
import { Log } from '../models/logs'

import { AnomalySource } from '../reference/anomaly-source'

import * as _ from 'lodash'

import 'rxjs/Rx'
import { SessionReviewState } from '../reference/session-review-state'
import { SessionDecisionType } from '../reference/session-decision-type'

@Injectable()
export class AssessmentService {
  readonly assessmentsEndpoint = '/api/assessments/'
  readonly fileEndpoint = '/api/file/'

  constructor(
    private datePipe: DatePipe,
    private clientContext: ClientContext,
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  getAssessment(assessmentId: number): Observable<Assessment> {
    return this.http.get<Assessment>(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}`,
    )
  }

  getAssessmentUsers(assessmentId: number): Observable<Assessment> {
    return this.http.get<Assessment>(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/users`,
    )
  }

  // tslint:disable-next-line: max-line-length
  getAssessmentSessions(
    assessmentId: number,
    date?: Date,
    minimumFlaggedPercentage?: number,
    reviewState?: number,
    studentName?: string,
    studentNumber?: string,
  ): Observable<Session[]> {
    const sessionsFiltersModel = {
      examinationId: assessmentId,
      date: date,
      minimumFlaggedPercentage: minimumFlaggedPercentage,
      reviewState: reviewState,
      studentName: studentName,
      studentNumber: studentNumber,
    }
    console.log(sessionsFiltersModel)
    // tslint:disable-next-line: max-line-length
    return this.http.post<Session[]>(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions`,
      sessionsFiltersModel,
    )
  }

  getAssessmentSessionLog(
    assessmentId: number,
    sessionId: number,
  ): Observable<Log[]> {
    return this.http
      .get<Log[]>(
        `${this.clientContext.clientServerUrl}${this.fileEndpoint}download/${assessmentId}/${sessionId}/log`,
      )
      .map((response: any) => JSON.parse(response) as Log[])
  }

  getAssessmentSessionThumbnails(
    assessmentId: number,
    sessionId: number,
    source: AnomalySource,
  ): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.clientContext.clientServerUrl}${this.fileEndpoint}download/${assessmentId}/${sessionId}/thumbnails/${source}`,
    )
  }

  createAssessment(schoolId: number, formData: any) {
    return this.http.post(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}schools/${schoolId}/createassessment`,
      formData,
    )
  }

  updateAssessment(formData: any) {
    return this.http.put(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${formData.assessmentId}`,
      formData,
    )
  }

  updateUsersBySchoolId(examinationId, userIds: any[]) {
    return this.http.put(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${examinationId}/users`,
      userIds,
    )
  }

  deleteAssessment(assessmentId: number) {
    return this.http.delete(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}`,
    )
  }

  deleteSession(assessmentId: number, sessionId: number) {
    return this.http.delete(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${sessionId}`,
    )
  }

  notifySupport(assessmentId: number, sessionId: number) {
    return this.http.post(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${sessionId}/notifySupport`,
      {},
    )
  }

  requestBundle(assessmentId: number, sessionId: number, password: string) {
    const request = {
      examinationSessionId: sessionId,
      bundlePassword: password,
    }
    return this.http.post(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/requestBundle`,
      request,
    )
  }

  getAssessmentSessionDetails(assessmentId, assessmentSessionId) {
    return this.http.get(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}`,
    )
  }

  getSequenceSuspiciousFrames(
    assessmentId,
    assessmentSessionId,
    sessionSequenceId,
  ) {
    return this.http.get(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}/sequences/${sessionSequenceId}/susframes`,
    )
  }

  setSessionReviewed(assessmentId: number, assessmentSessionId: number) {
    return this.http.put(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}/reviewed`,
      [],
    )
  }

  setSessionAuthenticated(assessmentId: number, assessmentSessionId: number) {
    return this.http.put(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}/authenticated`,
      [],
    )
  }

  revertSessionReviewed(assessmentId: number, assessmentSessionId: number) {
    return this.http.delete(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}/reviewed`,
    )
  }

  ignoreSessionFaceMatched(assessmentId: number, assessmentSessionId: number) {
    return this.http.put(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}/faceMatchedIgnored`,
      {},
    )
  }

  ignoreSessionAuthenticated(
    assessmentId: number,
    assessmentSessionId: number,
  ) {
    return this.http.put(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}/authenticatedIgnored`,
      {},
    )
  }

  getSessionAuthStatus(
    assessmentId: number,
    assessmentSessionId: number,
  ): Observable<SessionAuthStatus> {
    return this.http.get<SessionAuthStatus>(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}/authStatus`,
    )
  }

  addSessionNote(
    assessmentId: number,
    assessmentSessionId: number,
    note: string,
  ) {
    return this.http.put(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}/notes?notes=${note}`,
      [],
    )
  }

  addSessionView(assessmentId: number, assessmentSessionId: number) {
    const addSessionViewRequest = {
      examinationSessionId: assessmentSessionId,
    }
    // tslint:disable-next-line: max-line-length
    return this.http.post(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${assessmentSessionId}/createSessionView`,
      addSessionViewRequest,
    )
  }

  addSessionDecision(
    assessmentId: number,
    examinationSessionDecision: ExaminationSessionDecision,
  ) {
    debugger
    // tslint:disable-next-line: max-line-length
    return this.http.post(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/sessions/${examinationSessionDecision.examinationSessionId}/createSessionDecision`,
      examinationSessionDecision,
    )
  }

  sendNotification(assessmentId: number, sendNotification: SendNotification) {
    debugger
    // tslint:disable-next-line: max-line-length
    return this.http.post(
      `${this.clientContext.clientServerUrl}${this.assessmentsEndpoint}${assessmentId}/SendNotification`,
      sendNotification,
    )
  }

  convertToCSV(
    assessmentId: number,
    unitId: number,
    assessmentName: string,
    data: any,
  ) {
    let csvString = ''
    let row = ''
    let dateFormat = this.configService.config.dateFormat

    delete data[0].hasCardId
    delete data[0].hasFullReport
    delete data[0].hasSequences
    delete data[0].reviewState
    delete data[0].hasExaminationSessionView

    for (let index in data[0]) {
      index = index == 'assessmentName' ? 'Assessment Name' : index
      index = index == 'assessmentSessionId' ? 'Session ID' : index
      index = index == 'sessionKey' ? 'Session Key' : index
      index = index == 'studentName' ? 'Student Name' : index
      index = index == 'studentId' ? 'Student ID' : index
      index = index == 'startedAt' ? 'Start Time' : index
      index = index == 'endedAt' ? 'End Time' : index
      index = index == 'probability' ? 'Suspicious %' : index
      index = index == 'hasSequences' ? 'Has Recordings' : index
      index = index == 'utcOffsetInMinutes' ? 'UTC Offset (minutes)' : index
      index = index == 'sessionReviewState' ? 'Reviewed Status' : index
      index = index == 'examinationSessionDecisions' ? 'Decision' : index
      index = index == 'alerts' ? 'Alerts' : index
      index = index == 'reviewNotes' ? 'Notes' : index
      index = index == 'fullPlay' ? 'Full Report' : index
      index = index == 'summary' ? 'Summary' : index
      row += index + ','
      // row += this.splitCamelCase(index) + ',';
    }
    row += 'Assessment Name' + ','
    row += 'Full Report' + ','
    row += 'Summary'
    csvString += row + '\r\n'

    for (let i = 0; i < data.length; i++) {
      let line = ''
      delete data[i].hasCardId
      delete data[i].hasFullReport
      delete data[i].hasSequences
      delete data[i].reviewState
      delete data[i].hasExaminationSessionView

      delete data[i].recordingTypes
      if (data[i].sessionReviewState & SessionReviewState.authenticated) {
        data[i].sessionReviewState = 'Authenticated'
      } else if (data[i].sessionReviewState & SessionReviewState.faceMatched) {
        data[i].sessionReviewState = 'Face Matched'
      } else if (data[i].sessionReviewState & SessionReviewState.reviewed) {
        data[i].sessionReviewState = 'Reviewed'
      } else {
        data[i].sessionReviewState = 'No'
      }
      let decision = _.includes(
        data[i].examinationSessionDecisions,
        SessionDecisionType.confirmedBreach,
      )
        ? 'Confirmed Breach, '
        : ''
      decision += _.includes(
        data[i].examinationSessionDecisions,
        SessionDecisionType.potentialBreach,
      )
        ? 'Potential Breach, '
        : ''
      decision += _.includes(
        data[i].examinationSessionDecisions,
        SessionDecisionType.satisfactoryReview,
      )
        ? 'Satisfactory Review, '
        : ''
      decision =
        decision.length > 0
          ? decision.substring(0, decision.length - 2)
          : decision // strip extra ', '
      data[i].examinationSessionDecisions = decision
      data[i].startedAt =
        this.datePipe.transform(data[i].startedAt, dateFormat) +
        ' ' +
        this.datePipe.transform(data[i].startedAt, 'shortTime')
      data[i].endedAt =
        this.datePipe.transform(data[i].endedAt, dateFormat) +
        ' ' +
        this.datePipe.transform(data[i].endedAt, 'shortTime')
      data[i].probability =
        !data[i].probability || data[i].probability < 0
          ? '0 %'
          : data[i].probability.toFixed(2) + ' %'
      data[i].assessmentName = assessmentName
      data[
        i
      ].fullPlay = `${window.location.origin}/#/units/${unitId}/assessments/${assessmentId}/sessions/${data[i].assessmentSessionId}/session-view/${data[i].assessmentSessionId}`
      data[
        i
      ].summary = `${window.location.origin}/#/units/${unitId}/assessments/${assessmentId}/sessions/${data[i].assessmentSessionId}/summary-view/${data[i].assessmentSessionId}`

      for (let index in data[i]) {
        if (line != '') line += ','
        line += JSON.stringify(data[i][index])
      }
      csvString += line + '\r\n'
    }
    return csvString
  }

  splitCamelCase(word) {
    let output,
      i,
      l,
      capReg = /[A-Z]/
    output = []
    for (i = 0, l = word.length; i < l; i += 1) {
      if (i === 0) {
        output.push(word[i].toUpperCase())
      } else {
        if (i > 0 && capReg.test(word[i])) {
          output.push(' ')
        }
        output.push(word[i])
      }
    }
    return output.join('')
  }

  downloadCSV(assessmentId: any, csvData: any) {
    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    let fileName = `${assessmentId}_sessions_${new Date().toLocaleTimeString()}.csv`
    let link = document.createElement('a')
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
