import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ClientContext } from './client.context';
import { StatsRequest } from '../models/statistics';

@Injectable()
export class StatisticsService {

  readonly statisticsEndpoint = '/api/stats/';

  constructor(
    private http: HttpClient,
    private clientContext: ClientContext
  ) {}

  processingSequences(request: StatsRequest): Observable<number> {
    return this.http.get<number>(`${this.clientContext.clientServerUrl}${this.statisticsEndpoint}processing?from=${request.from}`);
  }

  processedSequences(request: StatsRequest): Observable<number> {
    return this.http.get<number>(`${this.clientContext.clientServerUrl}${this.statisticsEndpoint}processed?from=${request.from}&to=${request.to}`);
  }

  activeExams(): Observable<number> {
    return this.http.get<number>(`${this.clientContext.clientServerUrl}${this.statisticsEndpoint}active-exams`);
  }

  activeSessions(): Observable<number> {
    return this.http.get<number>(`${this.clientContext.clientServerUrl}${this.statisticsEndpoint}active-sessions`);
  }

  lockedSequences(request: StatsRequest): Observable<number> {
    return this.http.get<number>(`${this.clientContext.clientServerUrl}${this.statisticsEndpoint}locked-sequences?from=${request.from}&to=${request.to}`);
  }

  processedChart(request: StatsRequest): Observable<any> {
    return this.http.get<any>(`${this.clientContext.clientServerUrl}${this.statisticsEndpoint}processed-chart?from=${request.from}&to=${request.to}`);
  }
}
