import * as $ from 'jquery'
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http'
import { LocationStrategy, HashLocationStrategy } from '@angular/common'
import { AppRoutes } from './app.routing'
import { AppComponent } from './app.component'

import { HttpModule, Http } from '@angular/http'
import { MomentModule } from 'angular2-moment'
import { DatePipe } from '@angular/common'

import { AuthGuard } from './services/auth.guard'
import { JwtInterceptor } from './services/jwt.interceptor'
import { AccountService } from './services/account.service'
import { AccountContext } from './services/account.context'
import { HubService } from './services/hub.service'
import { ClientContext } from './services/client.context'
import { UserManagementService } from './services/user-management.service'
import { UnitService } from './services/unit.service'
import { AssessmentService } from './services/assessment.service'
import { SchoolService } from './services/school.service'
import { FacultyService } from './services/faculty.service'
import { ConfigService } from './services/config.service'
import { ReferenceDataService } from './services/reference-data.service'
import { SettingsService } from './services/settings.service'
import { GlobalErrorHandler } from './services/global-error-handler'
import { DataTableSettingsService } from './services/data-table-settings.service'
import { SearchService } from './services/search.service'
import { StatisticsService } from './services/statistics.service'

import { FlexLayoutModule } from '@angular/flex-layout'
import { FullComponent } from './layouts/full/full.component'
import { AppBlankComponent } from './layouts/blank/blank.component'
import { AppHeaderComponent } from './layouts/full/header/header.component'
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { DemoMaterialModule } from './demo-material-module'

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar'
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'

import { SharedModule } from './shared/shared.module'
import { DataService } from './services/data.service'
import { ReportsService } from './services/reports.service'

export function initApp() {
  return () => {
    console.log('App initialising..')
    // return Promise.all([
    // ]);
  }
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    AppBlankComponent,
    AppSidebarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,   
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    MomentModule,            
    RouterModule.forRoot(AppRoutes),
  ],  
  providers: [
    AccountService,
    AccountContext,
    HubService,
    ClientContext,
    UserManagementService,
    UnitService,
    DataTableSettingsService,
    AssessmentService,
    SchoolService,
    FacultyService,
    ConfigService,
    ReferenceDataService,
    SettingsService,
    DataService,
    ReportsService,
    SearchService,
    StatisticsService,
    DatePipe,
    {
      provide: AuthGuard,
      useClass: AuthGuard,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [],
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
