import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { forkJoin } from 'rxjs/observable/forkJoin'

import { environment } from '../../environments/environment'

import { ClientContext } from '../services/client.context'
import { ConfigService } from '../services/config.service'
import { ReferenceDataService } from '../services/reference-data.service'

import { Client } from '../models/client'
import { ClientConstants } from '../reference/client-consts'
import { User } from '../models/user'

import * as _ from 'lodash'

@Injectable()
export class HubService {
  readonly clientsEndpoint = '/clients/'

  private resetPasswordAtClient(
    clientSubDomain: string,
    emailAddress: string,
    captchaResponse: string,
  ) {
    const request = {
      emailAddress,
      captchaResponse,
    }
    const resetUrl = `${environment.clientServerProtocol}://${clientSubDomain}.${environment.clientServerDomain}/api/users/passwordreset`
    return this.http.put(resetUrl, request)
  }

  constructor(
    private http: HttpClient,
    private clientContext: ClientContext,
    private configService: ConfigService,
    private referenceDataService: ReferenceDataService,
  ) {}

  getClientForLogin(login: string): Observable<Client> {
    return this.http.get<Client>(
      `${environment.hubServerUrl}${this.clientsEndpoint}byusername/${login}`,
    )
  }

  getClientsForEmail(email: string): Observable<Client[]> {
    return this.http.get<Client[]>(
      `${environment.hubServerUrl}${this.clientsEndpoint}byemail/${email}`,
    )
  }

  resetPassword(
    emailAddress: string,
    captchaResponse: string,
  ): Observable<User> {
    return Observable.create((observer) => {
      this.getClientsForEmail(emailAddress).subscribe(
        (clients: Client[]) => {
          const resetActions = []
          _.each(clients, (client: Client) => {
            resetActions.push(
              this.resetPasswordAtClient(
                client.clientSubDomain,
                emailAddress,
                captchaResponse,
              ),
            )
          })

          forkJoin(resetActions).subscribe(
            () => {
              observer.next()
              observer.complete()
            },
            (err) => {
              observer.error(err)
            },
          )
        },
        (err) => {
          observer.error(err)
        },
      )
    })
  }

  setClient(clientMap: Client) {
    localStorage.setItem(ClientConstants.clientData, JSON.stringify(clientMap))
    this.clientContext.setClient(clientMap)
  }

  tryRestoreClient(): boolean {
    let clientData = localStorage.getItem(ClientConstants.clientData)
    if (!clientData) {
      return false
    }

    let client = JSON.parse(clientData)
    this.clientContext.setClient(client)

    return true
  }

  forgetClient() {
    this.configService.clearConfig()
    this.referenceDataService.clearReferenceData()
    localStorage.removeItem(ClientConstants.clientData)
    this.clientContext.clearClient()
  }

  loadClient(): Observable<void> {
    return Observable.create((observer) => {
      Promise.all([
        this.configService.loadConfig(),
        this.referenceDataService.loadReferenceData(),
      ])
        .then(() => {
          this.clientContext.markClientAsLoaded()

          observer.next()
          observer.complete()
        })
        .catch((err: any) => {
          observer.error(err)
        })
    })
  }
}
