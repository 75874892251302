<div>
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content>
    {{ data.content }}
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="m-t-20">
    <button mat-raised-button (click)="onCloseCancel()">Cancel</button>
    <button mat-raised-button [color]="confirmColor" (click)="onCloseConfirm()">
      {{ confirmLabel }}
    </button>
  </mat-dialog-actions>
</div>
