import { Routes } from '@angular/router'

import { FullComponent } from './layouts/full/full.component'
import { AppBlankComponent } from './layouts/blank/blank.component'

import { AuthGuard } from './services/auth.guard'
import { ReferenceDataResolver } from './services/reference-data.resolver'

export const AppRoutes: Routes = [
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full',
      },
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule',
      },
    ],
  },
  {
    path: '',
    component: FullComponent,    
    canActivate: [AuthGuard],
    resolve: {
      data: ReferenceDataResolver
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'search',
        loadChildren: './search/search.module#SearchModule',
      },
      {
        path: 'units',
        loadChildren: './units/units.module#UnitsModule',
      },
      {
        path: 'user-management',
        loadChildren:
          './user-management/user-management.module#UserManagementModule',
      },
      {
        path: 'faculty-management',
        loadChildren:
          './faculty-management/faculty-management.module#FacultyManagementModule',
      },
      {
        path: 'reporting',
        loadChildren: './reporting/reporting.module#ReportingModule',
      },
      {
        path: 'app-settings',
        loadChildren: './app-settings/app-settings.module#AppSettingsModule',
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
      },
      {
        path: 'statistics',
        loadChildren: './statistics/statistics.module#StatisticsModule',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'authentication/404',
  },
]
