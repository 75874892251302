import { ErrorHandler, Injectable, Injector } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { Router } from '@angular/router'
import { SentryConstants } from '../reference/sentry-consts'
import { environment } from '../../environments/environment'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
    Sentry.init({
      dsn: `https://${SentryConstants.SentryPublicKey}@sentry.io/${SentryConstants.SentryProjectId}`,
      environment: `${environment.environmentName}`,
    })
  }

  handleError(error) {
    let err = error.originalError || error
    if (err === 'Unauthorised!') {
      // unauthorized error - picked up by auth interceptor
      return
    }

    try {
      console.log(err)
      Sentry.captureException(err)
      let router = this.injector.get(Router)
      router.navigate(['/authentication/404'])
    } catch (ex) {
      console.log(ex)
    }
  }
}
