import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

import { AccountService } from './services/account.service';
import { AccountContext } from './services/account.context';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public isLoggedIn: boolean = false;
  public instanceName: string;
  
  constructor(
    private router: Router,
    private accountService: AccountService,
    private accountContext: AccountContext,
    private configService: ConfigService,
    private titleService: Title
    ) {
    this.instanceName = `Dashboard${(environment.environmentName !== 'Prod' ? ' (' + environment.environmentName + ')' : '')}`;
    titleService.setTitle(this.instanceName);

    this.accountService.tryRestoreLogin()
      .subscribe(loggedIn => {
        console.log('Auth session restored');
        
        if (this.router.url === '/authentication/login') {
          this.accountContext.redirectToHome();
        }
      }, err => {
        
      });

    this.accountContext.isLoggedIn
      .subscribe(loggedIn => {
        this.isLoggedIn = loggedIn;
      });

  }

  logOut() {
    this.accountService.logout();

    this.router.navigateByUrl("/authentication/login");
  }
}
