import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ClientContext } from './client.context';

import { Role } from '../models/role';
import { Faculty } from '../models/faculty';
import { School } from '../models/school';

import 'rxjs/Rx';

@Injectable()
export class ReferenceDataService {

  private readonly referenceEndpoint = '/api/reference-data/';

  private roles: Role[] = [];
  private faculties: Faculty[] = [];
  private schools: School[] = [];

  get getRoles(): Role[] {
    return this.roles;
  }

  get getFaculties(): Faculty[] {
    return this.faculties;
  }

  get getSchools(): School[] {
    return this.schools;
  }
  
	constructor(
    private http: HttpClient,
    private clientContext: ClientContext) { }

  loadReferenceData(): Promise<null> {
    return this.http.get<any>(this.clientContext.clientServerUrl + this.referenceEndpoint)
      .toPromise()
      .then((data: any) => {

        this.roles = data.roles;
        this.faculties = data.faculties;
        this.schools = data.schools;
        console.log('Reference data loaded.');

        return null;
      }, err => {
        console.log('Failed to load reference data.');

        return null;
      });
  }

  clearReferenceData() {
    this.roles = [];
    this.faculties = [];
    this.schools = [];
    console.log('Reference data cleared.');
  }

}