import 'rxjs/add/operator/do';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
 
import { AccountService } from './account.service';

import { AuthConstants } from '../reference/auth-consts';
import { ReferenceDataService } from './reference-data.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  
  constructor( private referenceDataService: ReferenceDataService,
    private injector: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let accessToken = localStorage.getItem(AuthConstants.authTokenKey);
    if (accessToken) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`
            }
        });
    }

    return next.handle(request).do(async (event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.injector.get(AccountService).logout();
          this.injector.get(Router).navigateByUrl("/authentication/login");
          throw 'Unauthorised!';
        } else {
          console.log('XHR request error...');
          console.log(JSON.stringify(err));
          if (err.error && err.error.length) {
            throw err.error[0];
          }
          throw 'An unexpected error has occurred';
        }
      }
    });
  }
}