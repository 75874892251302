import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { ClientContext } from './client.context';

import { User } from '../models/user';

@Injectable()
export class UserManagementService {

  readonly userEndpoint = '/api/users/';

  constructor(
    private http: HttpClient,
    private clientContext: ClientContext) { }


    getUsers(): Observable<User[]> {
      return this.http.get<User[]>(this.clientContext.clientServerUrl + this.userEndpoint);
    }

    getUser(userId): Observable<User> {
      return this.http.get<User>(`${this.clientContext.clientServerUrl}${this.userEndpoint}${userId}`);
    }

    createUser(userData) {
      return this.http.post(`${this.clientContext.clientServerUrl}${this.userEndpoint}`, userData);
    }

    updateUser(userData) {
      return this.http.put(`${this.clientContext.clientServerUrl}${this.userEndpoint}${userData.userId}`, userData);
    }

    updateSchoolUserRoles(roleData) {
      return this.http.put(`${this.clientContext.clientServerUrl}${this.userEndpoint}schooluserroles/${roleData.userId}`, roleData);
    }

    deleteUser(userId) {
      return this.http.delete(`${this.clientContext.clientServerUrl}${this.userEndpoint}${userId}`);
    }

}

