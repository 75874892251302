import { Injectable } from '@angular/core'

import { AccountContext } from '../../services/account.context'

export interface BadgeItem {
  type: string
  value: string
}
export interface Saperator {
  name: string
  type?: string
}
export interface ChildrenItems {
  state: string
  name: string
  type?: string
}

export interface Menu {
  state: string
  name: string
  type: string
  icon: string
  badge?: BadgeItem[]
  saperator?: Saperator[]
  children?: ChildrenItems[]
}

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    let menuItems: Menu[] = []

    menuItems.push({
      state: 'units',
      name: 'Units & Assessments',
      type: 'link',
      icon: 'assessment',
      children: [],
    })

    menuItems.push({
      state: 'search',
      name: 'Search',
      type: 'link',
      icon: 'search',
      children: [],
    })

    if (this.accountContext.canManageUsers()) {
      menuItems.push({
        state: 'user-management',
        name: 'User Management',
        type: 'link',
        icon: 'people',
        children: [],
      })
    }

    if (this.accountContext.canManageFaculties()) {
      menuItems.push({
        state: 'faculty-management',
        name: 'Faculty Management',
        type: 'link',
        icon: 'business',
        children: [],
      })
    }

    if (this.accountContext.isAdministrator()) {
      menuItems.push({
        state: 'reporting',
        name: 'Reporting',
        type: 'link',
        icon: 'assignment',
        children: [],
      })
    }

    if (this.accountContext.canManageSettings()) {
      menuItems.push({
        state: 'app-settings',
        name: 'Application Settings',
        type: 'link',
        icon: 'settings',
        children: [],
      })
    }

    menuItems.push({
      state: 'profile',
      name: `Profile (${
        !!this.accountContext.loggedInUser
          ? this.accountContext.loggedInUser.username
          : ''
      })`,
      type: 'link',
      icon: 'account_circle',
      children: [],
    })

    if (this.accountContext.isAdministrator()) {
      menuItems.push({
        state: 'statistics',
        name: 'Statistics',
        type: 'link',
        icon: 'info',
        children: [],
      })
    }

    return menuItems
  }

  constructor(private accountContext: AccountContext) {}
}
