import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { ClientContext } from './client.context';
import {
  SearchResponseFaculty,
  SearchResponseSchool,
  SearchResponseUnit,
  SearchResponseStudyPeriod,
  SearchResponseUsers,
  SearchResponseStudent,
  SearchResponseSession
} from '../models/search-response';
import { SearchRequest } from '../models/search-request';

@Injectable()
export class SearchService {

  readonly searchEndpoint = '/api/search/';

  constructor(
    private http: HttpClient,
    private clientContext: ClientContext
  ) {

  }

  searchFaculties(request: SearchRequest): Observable<SearchResponseFaculty[]> {
    return this.http.post<SearchResponseFaculty[]>(`${this.clientContext.clientServerUrl}${this.searchEndpoint}faculties`, request);
  }

  searchSchools(request: SearchRequest): Observable<SearchResponseSchool[]> {
    return this.http.post<SearchResponseSchool[]>(`${this.clientContext.clientServerUrl}${this.searchEndpoint}schools`, request);
  }
  
  searchUnits(request: SearchRequest): Observable<SearchResponseUnit[]> {
    return this.http.post<SearchResponseUnit[]>(`${this.clientContext.clientServerUrl}${this.searchEndpoint}units`, request);
  }
  
  searchStudyPeriods(request: SearchRequest): Observable<SearchResponseStudyPeriod[]> {
    return this.http.post<SearchResponseStudyPeriod[]>(`${this.clientContext.clientServerUrl}${this.searchEndpoint}studyPeriods`, request);
  }
  
  searchUsers(request: SearchRequest): Observable<SearchResponseUsers[]> {
    return this.http.post<SearchResponseUsers[]>(`${this.clientContext.clientServerUrl}${this.searchEndpoint}users`, request);
  }
  
  searchStudents(request: SearchRequest): Observable<SearchResponseStudent[]> {
    return this.http.post<SearchResponseStudent[]>(`${this.clientContext.clientServerUrl}${this.searchEndpoint}students`, request);
  }
  
  searchSessions(request: SearchRequest): Observable<SearchResponseSession[]> {
    return this.http.post<SearchResponseSession[]>(`${this.clientContext.clientServerUrl}${this.searchEndpoint}sessions`, request);
  }

  searchAssessments(request: SearchRequest): Observable<SearchResponseSession[]> {
    return this.http.post<SearchResponseSession[]>(`${this.clientContext.clientServerUrl}${this.searchEndpoint}exams`, request);
  }

}
