import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { ClientContext } from './client.context';
import { Unit } from '../models/unit';
import {
  Report,
  ReportUnProcessedResponse,
  AutoAuthenticationFailureResponse,
  ReportFlaggedFramePercentage,
  UserFilterModel,
  ConfirmedBreachesModel,
  ConfirmedBreachesResponse,
  StudentTestMinutesResponse,
  UnitFilterModel,
  AuditTrailModel,
  AuditTrailResponse,
  ReportFlaggedFrameResponse,
  SessionReviewedFrameResponse,
  SessionsReviewedPercentage
} from '../models/report';
import { StudyPeriod } from '../models/study-period';
import { User } from '../models/user';
import { Course } from '../models/course';

@Injectable()
export class ReportsService {

  readonly reportsEndpoint = '/api/reports/';

  constructor(
    private http: HttpClient,
    private clientContext: ClientContext) { }

    getUnits(schoolIds: any[]): Observable<Unit[]> {
      return this.http.get<Unit[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}/units/${schoolIds}`);
    }

    getCourses(schoolIds: any[]): Observable<Course[]> {
      return this.http.get<Course[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}/courses/${schoolIds}`);
    }

    getUnitsByCourseIds(courseIds: any[]): Observable<Unit[]> {
      return this.http.get<Unit[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}/unitsbycourseIds/${courseIds}`);
    }

    getReportUnProcessed(report: Report): Observable<ReportUnProcessedResponse[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<ReportUnProcessedResponse[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}report-unProcessed`, report);
    }

    getAutoAuthenticationFailure(report: Report): Observable<AutoAuthenticationFailureResponse[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<AutoAuthenticationFailureResponse[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}auto-authentication-failure`, report);
    }

    getFlaggedFramePercentage(report: ReportFlaggedFramePercentage): Observable<AutoAuthenticationFailureResponse[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<AutoAuthenticationFailureResponse[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}flagged-frame-percentage`, report);
    }

    getAllAssessments(): Observable<any[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.get<any[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}all-assessments`);
    }

    getConfirmedBreaches(report: ConfirmedBreachesModel): Observable<ConfirmedBreachesResponse[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<ConfirmedBreachesResponse[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}confirmed-breaches`, report);
    }

    getAuditTrail(report: AuditTrailModel): Observable<AuditTrailResponse[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<AuditTrailResponse[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}audit-trail`, report);
    }

    getStudentTestMinutes(report: Report): Observable<StudentTestMinutesResponse[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<StudentTestMinutesResponse[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}student-test-minutes`, report);
    }

    getStudyPeriods(): Observable<StudyPeriod[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.get<StudyPeriod[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}study-period`);
    }

    getUsers(report: Report): Observable<User[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<User[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}users`, report);
    }

    getFilteredUsers(userFilterModel: UserFilterModel): Observable<User[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<User[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}filtered-users`, userFilterModel);
    }

    getFilteredUnits(unitFilterModel: UnitFilterModel): Observable<Unit[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<Unit[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}filtered-units-assessments`, unitFilterModel);
    }

    getFlaggedFrames(report: Report): Observable<ReportFlaggedFrameResponse[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<ReportFlaggedFrameResponse[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}flagged-frame`, report);
    }

    getSessionsReviewed(report: SessionsReviewedPercentage): Observable<SessionReviewedFrameResponse[]> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<SessionReviewedFrameResponse[]>(`${this.clientContext.clientServerUrl}${this.reportsEndpoint}sessions-reviewed`, report);
    }


}
