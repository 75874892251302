import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

import { ClientContext } from './client.context';

import { Faculty } from "../models/faculty";
import { School } from "../models/school";
import { Course } from "../models/course";
import { Unit } from "../models/unit";
import { StudyPeriod } from "../models/study-period";

@Injectable()
export class FacultyService {

  readonly facultyEndpoint = "/api/faculties";

  readonly courseEndpoint = "/api/schools";

  readonly periodsEndpoint = "/api/studyperiods";


  constructor(
    private http: HttpClient,
    private clientContext: ClientContext) { }


  getFaculties(): Observable<Faculty[]> {
    return this.http.get<Faculty[]>(this.clientContext.clientServerUrl + this.facultyEndpoint);
  }

  getCourse(schoolId: number): Observable<Course[]>{
    return this.http.get<Course[]>(`${this.clientContext.clientServerUrl}${this.courseEndpoint}/${schoolId}/courses`);
  }

  getSchools(facultyId: number): Observable<School[]>{
    return this.http.get<School[]>(`${this.clientContext.clientServerUrl}${this.facultyEndpoint}/${facultyId}/schools`);
  }

  getUnits(schoolId: number, courseId: number): Observable<Unit[]>{
    return this.http.get<Unit[]>(`${this.clientContext.clientServerUrl}${this.courseEndpoint}/${schoolId}/courses/${courseId}/units`);
  }

  getPeriods(): Observable<StudyPeriod[]> {
    return this.http.get<StudyPeriod[]>(`${this.clientContext.clientServerUrl}${this.periodsEndpoint}`);
  }

  deleteUnit(schoolId: number, courseId: number, unitId: number){
    return this.http.delete(`${this.clientContext.clientServerUrl}${this.courseEndpoint}/${schoolId}/courses/${courseId}/units/${unitId}`);
  }

  deleteCourse(schoolId: number, courseId: number){
    return this.http.delete(`${this.clientContext.clientServerUrl}${this.courseEndpoint}/${schoolId}/courses/${courseId}`);
  }

  deleteFaculty(facultyId) {
    return this.http.delete(`${this.clientContext.clientServerUrl}${this.facultyEndpoint}/${facultyId}`);
  }

  deleteSchool(facultyId, schoolId) {
    return this.http.delete(`${this.clientContext.clientServerUrl}${this.facultyEndpoint}/${facultyId}/schools/${schoolId}`);
  }

  deletePeriods(studyPeriodId: number) {
    return this.http.delete(`${this.clientContext.clientServerUrl}${this.periodsEndpoint}/${studyPeriodId}`);
  }

  updateFacultyByFacultyId(faculty) {
    return this.http.put(`${this.clientContext.clientServerUrl}${this.facultyEndpoint}/${faculty.facultyId}`, faculty );
  }

  updateSchoolBySchoolId(school){
    return this.http.put(`${this.clientContext.clientServerUrl}${this.facultyEndpoint}/${school.facultyId}/schools/${school.schoolId}`, school);
  }

  updateCourseByCourseId(course) {
    return this.http.put(`${this.clientContext.clientServerUrl}${this.courseEndpoint}/${course.schoolId}/courses/${course.courseId}`, course);
  }

  updateUnitByUnitId(unit) {
    return this.http.put(`${this.clientContext.clientServerUrl}${this.courseEndpoint}/${unit.schoolId}/courses/${unit.courseId}/units/${unit.unitId}`, unit);
  }

  updatePeriodBySchoolPeriodId(period){
    return this.http.put(`${this.clientContext.clientServerUrl}${this.periodsEndpoint}/${period.studyPeriodId}`, period);
  }

  postStudyPeriod(formdata) {
    return this.http.post(`${this.clientContext.clientServerUrl}${this.periodsEndpoint}`, formdata);
  }

  postCourse(course) {
    return this.http.post(`${this.clientContext.clientServerUrl}${this.courseEndpoint}/${course.schoolId}/courses/`, course);
  }

  postUnit(unit) {
    return this.http.post(`${this.clientContext.clientServerUrl}${this.courseEndpoint}/${unit.schoolId}/courses/${unit.courseId}/units/`, unit);
  }

  postFaculty(faculty) {
    return this.http.post(`${this.clientContext.clientServerUrl}${this.facultyEndpoint}`, faculty);
  }

  postSchool(school) {
    return this.http.post(`${this.clientContext.clientServerUrl}${this.facultyEndpoint}/${school.facultyId}/schools`, school);
  }
}
