import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { MatIconModule, MatIconRegistry, MAT_DATE_LOCALE } from '@angular/material';

import { DemoMaterialModule} from '../demo-material-module';
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { RouteSpinnerComponent } from './spinners/route-spinner.component';
import { RequestLoaderComponent } from './spinners/request-spinner.component';
import { ExportComponent } from './export/export.component';
import { NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "l, LT"
  },
  display: {
    dateInput: "l, LT",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ConfirmDialogComponent,
    RouteSpinnerComponent,
    RequestLoaderComponent,
    ExportComponent,
  ],
  imports: [
    DemoMaterialModule,
    CommonModule,
    MatIconModule, 
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ConfirmDialogComponent,
    RouteSpinnerComponent,
    RequestLoaderComponent,
    ExportComponent,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
  ],
  entryComponents: [ 
    ConfirmDialogComponent 
  ],
  providers: [ 
    MenuItems,
    MatIconRegistry,          
    { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS }
  ]
})
export class SharedModule {
  constructor(
    public matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }
 }
