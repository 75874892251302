import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { ClientContext } from './client.context';

import { Assessment } from '../models/assessment';
import { Session } from '../models/session';

import 'rxjs/Rx';

@Injectable()
export class SchoolService {

  readonly schoolsEndpoint = '/api/schools/';

	constructor(
    private http: HttpClient,
    private clientContext: ClientContext) { }

  getCourses(schoolId: number): Observable<Assessment> {
    return this.http.get<Assessment>(`${this.clientContext.clientServerUrl}${this.schoolsEndpoint}${schoolId}/courses`);
  }

  getUnits(schoolId: number, courseId: number): Observable<Session[]> {
    return this.http.get<Session[]>(`${this.clientContext.clientServerUrl}${this.schoolsEndpoint}${schoolId}/courses/${courseId}/units`);
  }

  getUsersBySchoolId(schoolId): Observable<any[]>{
    return this.http.get<any[]>(`${this.clientContext.clientServerUrl}${this.schoolsEndpoint}${schoolId}/users`);
  }

}