import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'

import { ClientContext } from './client.context'

import { Unit } from '../models/unit'

//import * as moment from 'moment';

@Injectable()
export class UnitService {
  readonly unitsEndpoint = '/api/unitsummaries/'
  readonly schoolsEndpoint = '/api/schools/'
  readonly assessmentsEndpoint = '/api/assessments/'

  constructor(private http: HttpClient, private clientContext: ClientContext) {}

  getUnits(
    courseName?: string,
    unitName?: string,
    facultyIds?: number[],
    schoolIds?: number[],
    assessmentRange?: string,
    dateFrom?: Date,
  ): Observable<Unit[]> {
    const request = {
      courseName: courseName,
      unitName: unitName,
      facultyIds: facultyIds,
      schoolIds: schoolIds,
      assessmentRange: assessmentRange,
      dateFrom: dateFrom,
    }
    return this.http.post<Unit[]>(
      this.clientContext.clientServerUrl + this.unitsEndpoint,
      request,
    )
  }

  getUnit(unitId: number): Observable<any[]> {
    return this.http.get<any>(
      `${this.clientContext.clientServerUrl}${this.unitsEndpoint}${unitId}`,
    )
  }

  getAssessmentByUnitId(
    unitId: number,
    fromDate?: Date,
    toDate?: Date,
  ): Observable<any[]> {
    let fromDateFormatted = !!fromDate ? fromDate.toISOString() : 'null'
    let toDateFormatted = !!toDate ? toDate.toISOString() : 'null'
    return this.http.get<any[]>(
      `${this.clientContext.clientServerUrl}${this.unitsEndpoint}${unitId}/examinations/${fromDateFormatted}/${toDateFormatted}`,
    )
  }
}
