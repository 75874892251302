import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ReferenceDataService } from './reference-data.service';

@Injectable({ providedIn: 'root' })
export class ReferenceDataResolver implements Resolve<any> {

   constructor(private referenceDataService: ReferenceDataService) { }

   resolve() {
      return this.referenceDataService.loadReferenceData()
   }
   
}