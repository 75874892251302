import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router'
import { Observable } from 'rxjs/Observable'
import { Observer } from 'rxjs/Observer'

import { AccountContext } from './account.context'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private acountContext: AccountContext) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.acountContext.loggedInUser) {
      this.router.navigate(['/', 'authentication', 'login'])
    }
    return new Observable((observer: Observer<boolean>) => {
      this.acountContext.isLoggedIn.subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          observer.next(true)
          observer.complete()
        } else {
          this.router.navigate(['/', 'authentication', 'login'])
          observer.next(false)
          observer.complete()
        }
      })
    })
  }
}
