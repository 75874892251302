import { Injectable } from '@angular/core'

@Injectable()
export class DataTableSettingsService {
  fetchTableFilter(tableName: string): any {
    let filterData = sessionStorage.getItem(`${tableName}_filter`)
    return filterData
  }

  fetchTableSort(tableName: string): any {
    let sortData = sessionStorage.getItem(`${tableName}_sort`)
    return sortData != null ? JSON.parse(sortData) : null
  }

  fetchTableDateFilter(tableName: string): any {
    let sortData = sessionStorage.getItem(`${tableName}_date_filter`)
    return sortData != null ? JSON.parse(sortData) : null
  }

  storeTableFilter(tableName: string, filterValue: any) {
    if (!filterValue) {
      sessionStorage.removeItem(`${tableName}_filter`)
    } else {
      sessionStorage.setItem(`${tableName}_filter`, filterValue)
    }
  }

  storeTableSort(tableName: string, sortValue: any) {
    if (!sortValue) {
      sessionStorage.removeItem(`${tableName}_sort`)
    } else {
      sessionStorage.setItem(`${tableName}_sort`, JSON.stringify(sortValue))
    }
  }

  storeTableDateFilter(tableName: string, sortValue: any) {
    if (!sortValue || (sortValue && !sortValue.fromDate && !sortValue.toDate)) {
      sessionStorage.removeItem(`${tableName}_date_filter`)
    } else {
      sessionStorage.setItem(
        `${tableName}_date_filter`,
        JSON.stringify(sortValue),
      )
    }
  }

  StoreTableData(name, filteredDataKey: any[], propName, tableSortkey: any[]) {
    sessionStorage.setItem(name, JSON.stringify(filteredDataKey))
    sessionStorage.setItem(propName, JSON.stringify(tableSortkey))
  }
}
